import { loading, FLOW_PAGES_ENUM } from '../common'
import axios from '../axios-interceptor'
import store from '../'

const state = {
  token: '',
  documentNumber: '',
  phone: '',
  email: null,
}

const getters = {
  token: state => state.token,
  documentNumber: state => state.documentNumber,
  phone: state => state.phone,
  email: state => state.email ? state.email : localStorage.getItem('email') ? localStorage.getItem('email') : null,
}

const actions = {
    async login({ dispatch, commit, getters }, data) {
      try {
        const partner = store.getters['partner/partner'] || ''
        const formDataLogin = new FormData()

        formDataLogin.append('document', data.documentNumber)
        formDataLogin.append('cellphone', data.phone)

        commit('SET_DOCUMENT_NUMBER', data.documentNumber)
        commit('SET_PHONE', data.phone)

        if (getters.token && getters.email) {
          const formDataUpdateEmail = new FormData()
          formDataUpdateEmail.append('e-mail', getters.email)

          try {
            await axios.post('/api/email-update', formDataUpdateEmail, {
              headers: { parceira: partner },
            })
            dispatch('message/sendSuccess', 'E-mail atualizado com sucesso!', { root: true })
            dispatch('flux/nextStep', FLOW_PAGES_ENUM.LOGIN, { root: true })
            return
          } catch (error) {
            console.error('Erro ao atualizar o e-mail:', error)
            dispatch('message/sendAlert', 'Erro ao atualizar o e-mail.', { root: true })
            return
          }
        }

        const resp = await axios.post('/api/login', formDataLogin, {
          headers: { parceira: partner },
        })

        if (resp['next-step'] === 9) {
          commit('SET_EMAIL', resp['next-step-details']['e-mail'])
        } else {
          dispatch('flux/nextStep', FLOW_PAGES_ENUM.LOGIN, { root: true })
        }
      } catch (error) {
        console.error(error)
        dispatch('message/sendAlert', 'Erro durante o login.', { root: true })
      } finally {
        loading(dispatch, false)
      }
    },

  async validateSms({ dispatch }, code) {
    try {
      const clearedCode = code.split(' ').join('')
      if (clearedCode === '') {
        return dispatch(
          'message/sendAlert',
          'Por favor, verifique o código digitado.',
          {
            root: true,
          },
        )
      }

      const formData = new FormData()

      formData.append('sms-token', clearedCode)

      await axios.post('/api/token', formData)
      store.dispatch('proposal/getProposalsList')
      // dispatch('flux/nextStep', FLOW_PAGES_ENUM.VALIDATE_SMS, { root: true })
    } catch (error) {
      console.error(error)
    } finally {
      loading(dispatch, false)
    }
  },

  async reSend({ dispatch }, type) {
    try {
      const formData = new FormData();
      formData.append('type', type);

      await axios.post('/api/resend-token', formData);
      dispatch('message/sendSuccess', `${type === 'sms' ? 'SMS' : 'E-mail'} reenviado com sucesso!`, {
        root: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      loading(dispatch, false);
    }
  },

  async updateToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },
}

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_DOCUMENT_NUMBER(state, documentNumber) {
    state.documentNumber = documentNumber
  },
  SET_PHONE(state, phone) {
    state.phone = phone
  },
  SET_EMAIL(state, email) {
    state.email = email
    localStorage.setItem('email', email)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
