export default {

  default: {
    title: 'Bradesco',
    favicon: 'https://banco.bradesco/assets/classic/img/favicon.ico',
    '--bg-login-banner':
      'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/new_land_header.png")',
    '--bg-finished-banner':
      'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/new_end_header.png")',
    '--bg-logo': 'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/bradesco_logo.jpg")',
    '--logo-width': '130px',
    '--logo-height': '55px',
    '--first-color': 'rgb(204, 9, 47)', //burgundy
    '--fourth-color': 'rgb(22, 71, 119)', //dark-blue
    '--first-finished-color': 'rgb(204, 9, 47)', //burgundy
    '--second-finished-color': 'rgb(138, 40, 133)', //purple
    '--toggle-color': 'rgb(26, 102, 179)', //blue
    '--success-color': 'rgb(26, 102, 179)', //blue
    '--error-color': 'rgb(204, 9, 47)', //burgundy
  },

  nexyon: {
    title: 'Nexyon',
    favicon: 'https://nexyon.com.br/wp-content/uploads/2021/03/Logo_nexyon@2x.png',
    '--bg-login-banner': 'url("/nexyon/nexyon_inicio.png")',
    '--bg-finished-banner': 'url("/nexyon/nexyon_final.png")',
    '--bg-logo': 'url("/nexyon/nexyon_logo.png")',
    '--logo-width': '130px',
    '--logo-height': '55px',
    '--first-color': 'rgb(27, 38, 102)', //burgundy
    '--fourth-color': 'rgb(22, 71, 119)', //dark-blue
    '--first-finished-color': 'rgb(204, 9, 47)', //burgundy
    '--second-finished-color': 'rgb(138, 40, 133)', //purple
    '--toggle-color': 'rgb(26, 102, 179)', //blue
    '--success-color': 'rgb(26, 102, 179)', //blue
    '--error-color': 'rgb(204, 9, 47)', //burgundy
  },


  bradesco: {
    title: 'Bradesco',
    favicon: 'https://banco.bradesco/assets/classic/img/favicon.ico',
    '--bg-login-banner':
        'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/new_land_header.png")',
    '--bg-finished-banner':
        'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/new_end_header.png")',
    '--bg-logo': 'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/bradesco_logo.jpg")',
    '--logo-width': '130px',
    '--logo-height': '55px',
    '--first-color': 'rgb(204, 9, 47)', //burgundy
    '--fourth-color': 'rgb(22, 71, 119)', //dark-blue
    '--first-finished-color': 'rgb(204, 9, 47)', //burgundy
    '--second-finished-color': 'rgb(138, 40, 133)', //purple
    '--toggle-color': 'rgb(26, 102, 179)', //blue
    '--success-color': 'rgb(26, 102, 179)', //blue
    '--error-color': 'rgb(204, 9, 47)', //burgundy
  },

  ford: {
    title: 'Ford Credit',
    favicon:
        'https://www.ford.com.br/etc/designs/guxfoap/clientlibs/guxfoap/img/favicon.ico',
    '--bg-login-banner':
        'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/ford_banner.png")',
    '--bg-finished-banner':
        'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/ford_banner.png")',
    '--bg-logo':
        'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/ford_logo.png")',
    '--logo-width': '195px',
    '--logo-height': '36px',
    '--first-color': 'rgb(2, 118, 179)', //blue
    '--fourth-color': 'rgb(45, 67, 112)', //dark-blue
    '--first-finished-color': 'rgb(235, 238, 244)', //finish-border
    '--second-finished-color': 'rgb(235, 238, 244)', //finish-border
    '--toggle-color': 'rgb(44, 148, 204)', //blue
    '--success-color': 'rgb(44, 148, 204)', //blue
    '--error-color': 'rgb(204, 9, 47)', //burgundy
  },

  // jeep: {
  //   title: 'Jeep Bank',
  //   favicon:
  //     'https://www.jeep.com.br/content/dam/jeep/home/favicon.ico',
  //   '--bg-login-banner':
  //     'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/jeep_banner.png")',
  //   '--bg-finished-banner':
  //     'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/jeep_banner.png")',
  //   '--bg-logo':
  //     'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/jeep_logo.png")',
  //   '--logo-width': '184px',
  //   '--logo-height': '55px',
  //   '--first-color': 'rgb(162, 172, 129)', //moss
  //   '--fourth-color': 'rgb(162, 172, 129)', //moss
  //   '--first-finished-color': 'rgb(162, 172, 129)', //moss
  //   '--second-finished-color': 'rgb(162, 172, 129)', //moss
  //   '--toggle-color': 'rgb(162, 172, 129)', //moss
  //   '--success-color': 'rgb(45, 150, 205)', //blue
  //   '--error-color': 'rgb(204, 9, 47)', //burgundy
  // },

  harley: {
    title: 'Harley Davidson',
    favicon:
        'https://www.harley-davidson.com/etc/designs/h-d/favicon.ico',
    '--bg-login-banner':
        'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/harley_banner_1.jpg")',
    '--bg-finished-banner':
        'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/harley_banner_3.jpg")',
    '--bg-logo':
        'url("https://nexyonassets.s3.sa-east-1.amazonaws.com/harley_logo_1.png")',
    '--logo-width': '184px',
    '--logo-height': '55px',
    '--first-color': 'rgb(255, 102, 0)', // Pantone 165
    '--fourth-color': 'rgb(133, 57, 31)', // Pantone 1685
    '--first-finished-color': 'rgb(0, 62, 116)', // Pantone 541
    '--second-finished-color': 'rgb(90, 126, 146)', // Pantone 5415
    '--toggle-color': 'rgb(162, 172, 129)', //moss
    '--success-color': 'rgb(0, 120, 0)', // Pantone 356
    '--error-color': 'rgb(178, 38, 0)', // Pantone 1805
  },

}

export const BROWSERS = {
  FIREFOX: 'FIREFOX',
  SAFARI: 'SAFARI',
  CHROME: 'CHROME',
}

export const NUMBER_OF_STEPS = 4

export const DOC_TYPES = {
  CET: 'Custo Efetivo Total',
  CCB: 'Cédula de Crédito Bancário',
  DOFIC: 'Documento de Identificação',
  CPRES: 'Comprovante de Residência',
  SLFIE: 'Selfie',
  OTHER: 'Documentos adicionais',
  SEG: 'Seguro Prestamista',
}
